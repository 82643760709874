
import { useState, useEffect } from 'react';
import { Br, Cut, Line, Printer, Text, Row, render } from 'react-thermal-printer';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import DraggableDialog from './components/ModalDialog';
import { server } from './general.ts';

const Home = () => {
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [rows, setRows] = useState([]);
    const [category, setCategory] = useState(1);
    const [serialPort, setSerialPort] = useState(Object());
    const [customerName, setCustomerName] = useState("");
    const navigate = useNavigate();
    const [nonIncludeIngredients, setNonIncludeIngredients] = useState([])

    // useEffect(() => {
    //     console.log(nonIncludeIngredients)
    // }, [nonIncludeIngredients])

    const print = async () => {
        try {
            const data = await render(
                <Printer type="star">
                    <Text>{customerName}</Text>
                    <Br />
                    <Line />
                    {rows && rows.length > 0 && rows.map((row) => {
                        let filteredIngs = []
                        if (nonIncludeIngredients && nonIncludeIngredients.length > 0) {
                            filteredIngs = nonIncludeIngredients.filter(item => item.productId === row.id)
                        }
                        return (<>
                            <Text>{row.productName} - {row.qty}</Text>
                            {/* <Row left={row.productName} right={row.qty} /> */}
                            {/* <Row left={filteredIngs.map((ing, i) => {
                                if (ing.productId == row.id) {
                                    return (
                                        `${ing.ingredientName}${i + 1 == filteredIngs.length ? "" : ", "}`
                                    )
                                }
                            
                            })} /> */}
                            <Text>{filteredIngs.map((ing, i) => {
                                if (ing.productId == row.id) {
                                    return (
                                        `${ing.ingredientName}${i + 1 == filteredIngs.length ? "" : ", "}`
                                    )
                                }

                            })}</Text>
                            <Line />
                        </>)
                    })}
                </Printer>
            );

            let port = serialPort;
            if (Object.keys(port).length == 0) {
                if (window.navigator.serial)
                    port = await window.navigator.serial.requestPort();
                else if (window.navigator.bluetooth)
                    port = await window.navigator.bluetooth.requestDevice({
                        acceptAllDevices: true
                    });
                else {
                    alert("bluetooth detection failed")
                    return;
                }

                // await port.open({ baudRate: 9600 });
                setSerialPort(port)
            }

            const writer = port.writable?.getWriter();
            if (writer != null) {
                await writer.write(data);
                writer.releaseLock();

                setRows([])
                setCustomerName("")
            } else
                alert("Could not print on printer");
        } catch (error) {
            console.error('Printing error:', error);
            alert("Could not detect printer");
        }
    };

    const manageBT = async (pair) => {
        try {
            let port = Object();
            if (pair) {
                if (window.navigator.serial)
                    port = await window.navigator.serial.requestPort();
                else if (window.navigator.bluetooth)
                    port = await window.navigator.bluetooth.requestDevice({
                        acceptAllDevices: true
                    });
                else
                    alert("bluetooth detection failed")
                // await port.open({ baudRate: 9600 });
                setSerialPort(port)
            } else {
                setSerialPort("None")
            }
            return port;
        }
        catch (error) {
            console.error('Printing error:', error);
        }
    }

    useEffect(() => {
        fetch(`${server}/product/get_data`)
            .then(response => response.json())
            .then(data => setProducts(data.data.products))
            .catch(error => console.error('Error fetching data:', error));

        fetch(`${server}/category/get_data`)
            .then(response => response.json())
            .then(data => setCategories(data.data.categories))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const isDisabled = !rows || !rows.length || !rows.length === 0;

    let btName = "None";
    if (serialPort && serialPort.getInfo) {
        const info = serialPort.getInfo();
        btName = info && info.bluetoothServiceClassId;
    }

    return (
        <>
            <Grid
                container
                spacing={2}
                p={2}
                sx={{
                    paddingTop: "40px",
                    background: "#EE4540",
                    textAlign: "center", // Center the text
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <Grid container p={3}>
                    <Grid item xs={12} sm={12} md={6} >
                        <Typography sx={{ fontFamily: 'sans-serif', fontWeight: "bold", color: "#FFF" }} variant="h5" gutterBottom>
                            ALADDINS HOUSTON RESTAURANT
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} sx={{ display: "flex", justifyContent: "space-around" }}>
                        <Grid item xs={12} sm={12} md={8} sx={{ textAlign: "right" }}>
                            <Typography sx={{ fontWeight: 'bold', color: "#FFF", fontSize: 12 }}>Connected BT Printer: {btName}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2} sx={{ textAlign: "right" }} >
                            <Button variant="contained" disabled={btName != "None"} sx={{ fontSize: 12 }} onClick={() => { manageBT(true) }}>Pair</Button>
                        </Grid >
                        <Grid item xs={6} sm={6} md={2} >
                            <Button variant="contained" disabled={btName == "None"} sx={{ fontSize: 12 }} onClick={() => { manageBT(false) }}>Unpair</Button>
                        </Grid >
                    </Grid >
                </Grid >
            </Grid >
            {/* <Divider sx={{marginTop: "10px"}} /> */}
            < Grid container spacing={2} p={2} >
                <Grid item lg={2} xs={12} >
                    <Paper variant="outlined" sx={{ padding: "15px", height: "500px", overflowX: "auto" }} >
                        <Grid container spacing={2}>
                            {categories.map(cat => {
                                if (cat.status != "Deactivated")
                                    return (<Grid item lg={12} xs={4} >
                                        <Button key={cat.id} sx={{ borderRadius: "5px", border: "1px solid black", height: "80px", width: "100%", background: cat.id == category ? "#113245" : cat.color }} className='dynamicFontSize' variant="contained"
                                            onClick={() => {
                                                setCategory(cat.id)
                                            }}
                                        ><strong>{cat.name}</strong></Button>
                                    </Grid>)
                            })}
                        </Grid>

                    </Paper>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Paper variant="outlined" sx={{ padding: "15px", height: "500px", overflowX: "auto" }}>
                        <Grid container spacing={2}>
                            {products.map(product => (
                                <>
                                    {category === product.categoryId && <Grid item xs={4}>
                                        <Button key={product.id} sx={{ borderRadius: "5px", border: "1px solid black", height: "100px", width: "100%", background: product.color }} className='dynamicFontSize' variant="contained"
                                            onClick={() => {
                                                let x = [...rows]
                                                let check = false
                                                for (let i = 0; i < x.length; i++) {
                                                    if (x[i].id == product.id) {
                                                        x[i].qty++
                                                        check = true
                                                        break
                                                    }
                                                }
                                                if (!check) {
                                                    x.push({ id: product.id, productName: product.name, qty: 1 })
                                                }
                                                setRows(x)
                                            }}
                                        ><strong>{product.name}</strong></Button>
                                    </Grid>}
                                </>
                            ))}
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item lg={4} xs={12}>
                    <Paper variant="outlined" sx={{ padding: "15px", height: "500px" }}>
                        <Grid container spacing={2} p={2}>
                            <TextField value={customerName}
                                onChange={(e) => {
                                    setCustomerName(e.target.value.toUpperCase().replace("  ", " "))
                                }}
                                sx={{
                                    width: "100%", background: "#009BD6", borderRadius: "3px", mb: "5px",
                                    '& .MuiInputLabel-root': {
                                        fontWeight: 'bold',
                                        color: "#000"
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: '#000',
                                    },
                                    '& .MuiInputBase-input': {
                                        height: "40px",
                                        color: '#FFF',
                                        fontSize: 18
                                    },
                                }} id="standard-basic" label="Customer Name" variant="filled" />
                            <TableContainer component={Paper} sx={{ height: "420px", overflow: "auto" }}>
                                <Table sx={{ minWidth: 100 }} aria-label="simple table">
                                    <TableHead sx={{
                                        background: "#009BD6"
                                    }}>
                                        <TableRow>
                                            <TableCell><Typography sx={{ fontWeight: 'bold', color: "#000" }}>Items</Typography></TableCell>
                                            <TableCell width={20}><Typography sx={{ fontWeight: 'bold', color: "#000" }}>Qty</Typography></TableCell>
                                            <TableCell width={20}><Typography sx={{ fontWeight: 'bold', color: "#000" }}>Ingredients</Typography></TableCell>
                                            <TableCell width={20}><Typography sx={{ fontWeight: 'bold', color: "#000" }}>Actions</Typography></TableCell>
                                            {/* <TableCell align="right"><Typography sx={{ fontWeight: 'bold' }}>Qty</Typography></TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows && rows.length && rows.length > 0 ? rows.map((row, i) => {
                                            let filteredIngs = []
                                            if (nonIncludeIngredients && nonIncludeIngredients.length > 0) {
                                                filteredIngs = nonIncludeIngredients.filter(item => item.productId === row.id)
                                            }
                                            return (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <Typography sx={{ color: "#000" }}>{row.productName}</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography sx={{ textAlign: 'center', color: "#000" }}>{row.qty}</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography sx={{ textAlign: 'center', color: "#000" }}>{filteredIngs.length > 0 && filteredIngs.map((ing, i) => {
                                                            console.log(row)
                                                            // if(ing.productId == row.id){
                                                            return (<>{ing.ingredientName}{i + 1 == filteredIngs.length ? "" : ","} </>)
                                                            // }
                                                        })}</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <IconButton title={row.qty && row.qty > 1 ? "Lessen Qty" : " Remove row"} sx={{ color: "#EE4540" }} onClick={() => {
                                                            let x = [...rows]
                                                            if (row.qty && row.qty > 1) {
                                                                x[i].qty = row.qty - 1
                                                                setRows(x)
                                                            }
                                                            else {
                                                                // debugger
                                                                x.splice(i, 1);
                                                                setRows(x)
                                                            }
                                                        }}>
                                                            {row.qty && row.qty > 1 ? <RemoveIcon /> : <CloseIcon />}
                                                        </IconButton>

                                                        <DraggableDialog productId={row.id} nonIncludeIngredients={nonIncludeIngredients} setNonIncludeIngredients={setNonIncludeIngredients} />

                                                        {/* <IconButton title={"Ingredients"} sx={{ color: "#EE4540" }} aria-label="delete" onClick={() => {
                                                        
                                                    }}>
                                                        <img src="/bowl.png" alt="Custom Icon" style={{ width: '20px', height: '20px' }} />
                                                    </IconButton> */}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }) : <></>}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2} p={2} mt={1} sx={{
                padding: "15px", width: "100%",
                textAlign: "center", // Center the text
                display: "flex",
                flexDirection: "row",
                justifyContent: "right",
            }}>

                <Button sx={{ borderRadius: "5px", border: "1px solid black", fontSize: 18, marginRight: "10px", background: "#EE4540" }} variant="contained"
                    onClick={() => {
                        setRows([])
                        setCustomerName("")
                    }}
                >
                    Clear
                </Button>
                <Button
                    sx={{ borderRadius: '5px', border: '1px solid black', fontSize: 18, marginRight: "10px", background: "#159947" }}
                    variant="contained"
                    onClick={print}
                    disabled={isDisabled}
                >
                    B/T Print
                </Button>
                <Button
                    sx={{ borderRadius: '5px', border: '1px solid black', fontSize: 18, background: "#159947" }}
                    variant="contained"
                    onClick={() => {
                        console.log(nonIncludeIngredients)
                        localStorage.setItem('customerName', customerName);
                        localStorage.setItem('rows', JSON.stringify(rows));
                        localStorage.setItem('nonIncludeIngredients', JSON.stringify(nonIncludeIngredients));
                        navigate('/print') // Pass rows data as state when navigating to /print
                    }}
                    disabled={isDisabled}
                >
                    Print
                </Button>
            </Grid>
            {/* <Paper variant="outlined">

      </Paper> */}
            {/* <button onClick={print}>Print</button> */}
        </>)
}

export default Home