import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { server } from '../general.ts';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function DraggableDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [ingredients, setIngredients] = React.useState([])

    React.useEffect(() => {
        fetch(`${server}/ingredient/get_data`)
            .then(response => response.json())
            .then(data => setIngredients(data.data.ingredients))
            .catch(error => console.error('Error fetching data:', error));
    }, [])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open draggable dialog
      </Button> */}
            <IconButton title={"Ingredients"} sx={{ color: "#EE4540" }} aria-label="delete" onClick={handleClickOpen}>
                <img src="/bowl.png" alt="Custom Icon" style={{ width: '20px', height: '20px' }} />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                fullScreen
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Ingredients
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} p={2}>
                        {ingredients && ingredients.length && ingredients.length > 0 && ingredients.map(ingredient => {
                            const color = props.nonIncludeIngredients.filter(item => item.id === ingredient.id && item.productId == props.productId).length === 0 ? "#68c6a4" : "#113245"
                            return (<>
                                {<Grid item xs={3}>
                                    <Button key={ingredient.id}
                                        sx={{
                                            borderRadius: "5px",
                                            border: "1px solid black",
                                            height: "80px",
                                            width: "100%",
                                            background: color,
                                            fontSize: 18,
                                            
                                        }}
                                        variant="contained"
                                        onClick={() => {
                                            let x = [...props.nonIncludeIngredients]
                                            let check = false
                                            let index = -1
                                            for (let i = 0; i < x.length; i++) {
                                                if (x[i].id == ingredient.id && x[i].productId == props.productId) {
                                                    index = i
                                                    check = true
                                                    break
                                                }
                                            }
                                            if (!check) {
                                                x.push({ id: ingredient.id, ingredientName: ingredient.name, qty: 1, productId: props.productId })
                                            }
                                            else{
                                                x.splice(index, 1)
                                            }
                                            props.setNonIncludeIngredients(x)
                                        }}
                                    ><strong>{ingredient.name}</strong></Button>
                                </Grid>}
                            </>)

                        })}
                    </Grid>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={handleClose}
                 sx={{ borderRadius: "5px", border: "1px solid black", fontSize: 18, marginRight: "10px", background: "#159947" }} variant="contained" >
                    Done
                </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}