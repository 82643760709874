import { useState, useEffect } from 'react';
import { render, Printer, Text } from 'react-thermal-printer';
import './App.css';
import Home from './Home';
import PrintableSection from './Print';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';


// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/print" element={<PrintableSection />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
