// PrintableSection.jsx
import React, { useEffect, useState } from 'react';

const PrintableSection = () => {
    const storedRows = localStorage.getItem('rows');
    const ingredients = localStorage.getItem('nonIncludeIngredients');
    const customerName = localStorage.getItem('customerName');
    const rows = JSON.parse(storedRows);
    const nonIncludeIngredients = JSON.parse(ingredients);
    const [count, setCount] = useState(0)

    useEffect(() => {
        window.print()
    }, [])

    const isMobile = () => {
        const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        return regex.test(navigator.userAgent);
    }

    window.onafterprint = () => {
        if (isMobile()) {
            setTimeout(() => {
                window.location.href = "/"
            }, 3000);
            console.log("Mobile device detected");
        } else {
            window.location.href = "/";
            console.log("Desktop device detected");
        }
    }

    return (
        <div style={{ width: "216px" }}>
            <strong style={{ fontSize: 20 }}>C-NAME: </strong>
            <strong style={{ fontSize: 20 }}><p style={{ maxWidth: "216px" }}>{customerName}</p></strong>
            <table style={{ width: "300px" }}>
                <thead>
                    <tr >
                        <th style={{ borderBottom: '1px solid black', padding: "10px" }}>ITEMS</th>
                    </tr>
                </thead>
                <tbody>
                    {rows && rows.length > 0 && rows.map((row) => {
                        let filteredIngs = []
                        if (nonIncludeIngredients && nonIncludeIngredients.length > 0) {
                            filteredIngs = nonIncludeIngredients.filter(item => item.productId === row.id)
                        }
                        return (
                            <>
                                <tr
                                    key={row.productName}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <td style={{ padding: "4px" }}>{`${row.qty} - ${row.productName}`}</td>
                                </tr>
                                <tr
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <td style={{ borderBottom: '1px dotted black', padding: "4px", fontSize: 14, fontWeight: 600 }}>{filteredIngs.length > 0 && <>(
                                        {filteredIngs.map((ing, i) => {
                                            if (ing.productId == row.id) {
                                                return (
                                                    <>{`${ing.ingredientName}`}{i + 1 == filteredIngs.length ? "" : ", "}</>
                                                )
                                            }
                                        })}
                                        )</>}</td>
                                </tr>
                            </>
                        )
                    })}
                </tbody>
            </table>
        </div>)
}

export default PrintableSection;
